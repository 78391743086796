<template>
  <div class="center-aligned">
    <img :src="backgroundImg" class="background-image" :style="imgStyle" />
    <div class="text" :style="fontFamily">{{ value }}</div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
      default: 'gb',
    },
  },
  computed: {
    isUk() {
      if (this.token === 'uk') return true;
      if (this.country === 'gb') return true;
      return false;
    },
    isEurope() {
      if (this.token === 'monitor') return true;
      return this.country === 'de' || this.token === 'europe';
    },
    isAsia() {
      return this.token === 'apac';
    },
    isUSA() {
      return this.token === 'usa';
    },
    imgStyle() {
      if (this.isUk) {
        return {
          'border-radius': '3px',
          'border-style': 'solid',
          'border-color': '#383838',
          width: '100%',
        };
      } else if (this.isAsia) {
        return {
          'max-width': '190px',
        };
      } else if (this.isUSA) {
        return {
          'max-width': '190px',
        };
      }
      return undefined;
    },
    backgroundImg() {
      if (this.isUk) {
        return require('@/assets/img/gb-licence-plate.png');
      }
      if (this.isEurope) {
        return require('@/assets/img/de-licence-plate-removebg-preview.png');
      }
      if (this.isAsia) {
        return require('@/assets/img/malaysia-licence-plate.png');
      }
      if (this.isUSA) {
        return require('@/assets/img/usa-licence-plate.png');
      }
      return undefined;
    },
    fontFamily() {
      if (this.isUk) {
        return {
          'font-family': 'Charles Wright',
          color: 'black',
          'font-size': '1.8em',
        };
      }
      if (this.isEurope) {
        return {
          'font-family': 'FE Schrift',
          color: 'black',
          'font-size': '2.4em',
          'margin-left': '15px',
          'margin-top': '2px',
        };
      }
      if (this.isAsia) {
        return {
          'font-family': 'Charles Wright',
          color: 'white',
          'font-size': '1.5em',
        };
      }
      if (this.isUSA) {
        return {
          'font-family': 'Plat Nomor',
          color: 'black',
          'font-size': '1.8em',
        };
      }
      return {
        'font-family': 'Charles Wright',
        'font-size': '2.2em',
      };
    },
  },
});
</script>

<style scoped>
@font-face {
  font-family: 'Charles Wright';
  src: url('/fonts/charles_wright/CharlesWright-Bold.ttf');
  font-style: bold;
}

@font-face {
  font-family: 'FE Schrift';
  src: url('/fonts/fe_schrift/GlNummernschildEng-XgWd.ttf');
  font-style: bold;
}

@font-face {
  font-family: 'Plat Nomor';
  src: url('/fonts/plat_nomor/PlatNomor-WyVnn.ttf');
  font-style: bold;
}

.center-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.text {
  position: absolute;
  color: black;
  padding: 20px;
}
</style>
